import * as React from "react";
import Container from "../components/comp-layouts/Container";
import Grid from "../components/comp-layouts/Grid";
import Aligner from "../components/comp-layouts/Aligner";
import * as Icon from "react-feather";
import Owl from "../components/comp-layouts/Owl";
import styled from "styled-components";
import Layout from "../components/global/layout";
import SEO from "../components/global/seo";
import T from "../components/Text";
import theme from "../theme/theme.json";
import { Link } from "gatsby";
import teamDropper from "../components/global/Navbar/teamDropper.json";

const About = () => {
  return (
    <>
      <SEO
        title="About Us | Seabird & Plastics Research"
        description="Adrift Lab is a dedicated group of researchers studying all things adrift in the ocean, including plastic, chemicals, and wildlife."
        slug="/about"
      />
      <Layout>
        <Container mt="60px" mb="50px">
          <Owl>
            <h1>About Adrift Lab</h1>
            <FrameWrapper>
              <iframe
                src="https://www.youtube.com/embed/qcD5vEXs8bM"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </FrameWrapper>
            <p>
              Adrift Lab is a dedicated{" "}
              <Link to="/core-team">group of researchers</Link> studying all
              things adrift in the ocean, including plastic, chemicals, and
              wildlife. Our diverse and supportive lab includes graduate
              students and post-docs from all over the globe, who offer
              specialist skills in ecotoxicology, statistical analysis, and
              marine ecology.
            </p>
            <p>
              At Adrift Lab, we analyse data gathered at sea and on beaches to
              identify long-term trends and quantify the impact of marine
              plastic pollution. Our projects range from monitoring sentinel
              species, to developing tools to quantify sub-lethal effects
              following ingestion by wildlife. We use the information generated
              from our research outputs (see our projects) to engage the broader
              community and inform policy-making, with an aim to drive positive
              change for the ecology of our world’s oceans.
            </p>
            <figure>
              <blockquote>
                "Developed countries alone spend $17 billion annually on pet
                food and $34 billion on slimming products. It is thus clear to
                us that the developed world could fund effective conservation if
                it chose to"
              </blockquote>
              <figcaption>- Balmford & Whitten, 2003</figcaption>
            </figure>
            <p>
              We offer a unique and welcoming lab environment, and we’re always
              looking for new students who are passionate and committed to
              applied conservation research
            </p>
          </Owl>
        </Container>
      </Layout>
    </>
  );
};

export default About;

const FrameWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  background: #f0f0f3;
  margin: 3rem 0;

  & ::after {
    display: block;
    content: "";
    padding-top: 56.25%;
  }
  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
